import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Menu } from 'antd';
import styled from 'styled-components';
import parseCategoryUrl from 'lib/parseCategoryUrl';

const { SubMenu, Item } = Menu;

const hasChildren = ({ children }): boolean => {
    return typeof children !== 'undefined' && children.length > 0;
};

const MenuStyled = styled(Menu)`
    justify-content: space-between;
    display: flex;
    color: ${(props) => props.theme.colors.brownishGrey};
`;

const MenuItem = styled(Item)`
    font-size: 18px;
    font-weight: 300;
    a {
        color: ${(props) => props.theme.colors.brownishGrey};
    }
    a {
        color: ${(props) => props.theme.colors.brownishGrey};
    }
`;

const SubMenuStyled = styled(SubMenu)`
    @media (min-width: 768px) {
        font-size: 18px;
    }

    @media (min-width: 992px) {
        font-size: 19px;
    }

    @media (min-width: 1200px) {
        font-size: 20px;
    }
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    a {
        color: ${(props) => props.theme.colors.brownishGrey};
    }
`;

const SubMenuItem = ({ item }) => {
    return <Link href={`/${item.seoSlug}`}>{item.title}</Link>;
};

function renderSubmenu(item) {
    if (hasChildren(item)) {
        return (
            <SubMenuStyled key={item.seoSlug} title={<SubMenuItem item={item} />}>
                {item.children.map((child) => renderSubmenu({ ...child, parent: item }))}
            </SubMenuStyled>
        );
    }
    return (
        <MenuItem key={item.seoSlug}>
            <Link href={parseCategoryUrl(item)}>{item.title}</Link>
        </MenuItem>
    );
}
interface Props {
    items: MenuItem[];
}

export const MenuSection = ({ items }: Props): JSX.Element => {
    const [activeKeys, setActiveKeys] = useState(['home']);
    const router = useRouter();

    useEffect(() => {
        const path = router.asPath.split('/').filter((elem) => elem);
        if (path.length === 0) {
            setActiveKeys(['home']);
        }
        setActiveKeys(path);
    }, [router.asPath]);

    return (
        <MenuStyled selectedKeys={activeKeys} mode="horizontal">
            {items.map((item) => renderSubmenu(item))}
        </MenuStyled>
    );
};

export default MenuSection;
