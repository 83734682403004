import Head from 'next/head';
import getConfig from 'next/config';
import { Layout } from 'antd';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import Header from 'layout/Basic/Header';
import Footer from 'layout/Basic/Footer';

const Skyscraper = dynamic(() => import('components/Ads/Skyscraper'), {
    ssr: false
});

const { Content } = Layout;

const Container = styled(Layout)`
    position: relative;
    min-height: 100vh;
`;

const Main = styled(Layout)`
    @media (max-width: 1252px) {
        width: 100%;
    }

    @media (min-width: 1252px) {
        width: calc(100% - 260px);
        max-width: 1200px;
    }

    margin: auto;
`;

const StyledContent = styled(Content)`
    padding: 35px 4px 20px 4px;
    /* overflow: hidden; */
`;

interface Props {
    children: JSX.Element | JSX.Element[];
    ads: Pick<PageAds, 'skyscraper' | 'billboard' | 'wideboardTop'>;
}

const BasicLayout = ({ children, ads }: Props): JSX.Element => {
    const { url, name } = getConfig().publicRuntimeConfig;
    const { skyscraper } = ads;
    return (
        <Container>
            <Head>
                <link rel="icon" href={'/static/images/favicon.ico'} />
            </Head>
            <Main>
                <Header url={url} name={name} ads={ads} />
                <Skyscraper position="left" id={skyscraper[0]} />
                <StyledContent>{children}</StyledContent>
                <Skyscraper position="right" id={skyscraper[1]} />
            </Main>
            <Footer />
        </Container>
    );
};

export default BasicLayout;
