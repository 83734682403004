import React from 'react';
import Button from './Button';
import { ButtonProps } from 'antd';

interface Props extends ButtonProps {
    isOpen: boolean;
}
const Burger = ({ isOpen, ...props }: Props) => (
    <Button aria-label="Toggle menu" aria-expanded={isOpen} open={isOpen} {...props}>
        <span />
        <span />
        <span />
    </Button>
);

export default Burger;
