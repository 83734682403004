import styled from 'styled-components';
import NImage from 'next/image';

const Image = styled(NImage)`
    bottom: 0;
    max-width: 345px;
    height: 90px;
    width: 100%;
    padding: 10px;
    object-position: 50% 50%;
`;

export default Image;
