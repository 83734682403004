import styled from 'styled-components';
import { Button, ButtonProps } from 'antd';

interface Props extends ButtonProps {
    open: boolean;
}

export const StyledBurger = styled(Button)<Props>`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    ${({ open }) => open && 'z-index: 1001;'};
    ${({ open }) => open && 'padding-left: 4px;'};
    span {
        width: 2rem;
        height: 0.25rem;
        background: ${({ open }) => (open ? 'red' : 'black')};
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
        &:first-child {
            transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
        }
        &:nth-child(2) {
            opacity: ${({ open }) => (open ? '0' : '1')};
            transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
        }
        &:nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
        }
    }
`;

export default StyledBurger;
