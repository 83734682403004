import React from 'react';
import styled from 'styled-components';
import { merriweather } from 'styles/GlobalStyle';

const H1 = styled.h1`
    color: ${(props) => props.color || props.theme.colors.primary};
    font-family: ${merriweather.style.fontFamily};
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.36px;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
    display: inline;
`;

const H2 = styled.h2`
    color: ${(props) => props.color || props.theme.colors.primary};
    font-family: ${merriweather.style.fontFamily};
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.36px;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
    display: inline;
`;

const H3 = styled.h3`
    font-size: 20px;
    font-weight: 300;
    font-family: ${merriweather.style.fontFamily};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    margin: 0;
    color: ${(props) => props.color || props.theme.colors.greyishBrown};
`;

const H4 = styled.h4`
    color: ${(props) => props.color || props.theme.colors.primary};
    font-family: ${merriweather.style.fontFamily};
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.36px;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
`;

const H5 = styled.h5`
    color: ${(props) => props.color || props.theme.colors.primary};
    font-family: ${merriweather.style.fontFamily};
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.36px;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
`;

const H6 = styled.h6`
    color: ${(props) => props.color || props.theme.colors.primary};
    font-family: ${merriweather.style.fontFamily};
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.36px;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
`;

interface Props
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
    children: JSX.Element | string | JSX.Element[] | string[];
    level: number;
    id?: string;
}

const Heading = ({ level, children, id, ...restProps }: Props): JSX.Element => {
    switch (level) {
        case 1:
            return (
                <H1 id={id} {...restProps}>
                    {children}
                </H1>
            );
        case 2:
            return (
                <H2 id={id} {...restProps}>
                    {children}
                </H2>
            );
        case 3:
            return (
                <H3 id={id} {...restProps}>
                    {children}
                </H3>
            );
        case 4:
            return (
                <H4 id={id} {...restProps}>
                    {children}
                </H4>
            );
        case 5:
            return (
                <H5 id={id} {...restProps}>
                    {children}
                </H5>
            );
        case 6:
            return (
                <H6 id={id} {...restProps}>
                    {children}
                </H6>
            );
    }
};

export default Heading;
