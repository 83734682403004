import { Row } from 'antd';
import styled from 'styled-components';
import SectionTitle from './SectionTitle';
import Heading from './Heading';
import SubTitle from './SubTitle';

interface Props {
    $marginTop?: number;
}

const Section = styled(Row)<Props>`
    margin-top: ${({ $marginTop }) => {
        if ($marginTop === undefined) {
            return '35px';
        }

        return `${$marginTop}px`;
    }};
`;

export default Section;

export { Section, SectionTitle, Heading, SubTitle };
