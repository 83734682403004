import React, { useState, useEffect } from 'react';
import { Row, Col, Layout } from 'antd';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import MainMenu from 'containers/MainMenu';
import TopMenu from 'containers/TopMenu';
import Image from './Image';
import HamburgerButton from 'components/HamburgerButton';
import Logotype from 'public/static/images/logo.svg';

const MOBILE_HEADER_WIDTH = 992;

const { Header } = Layout;

const Wideboard = dynamic(() => import('components/Ads/Wideboard'), {
    ssr: true
});
const Billboard = dynamic(() => import('components/Ads/Billboard'), {
    ssr: true
});

const Drawer = dynamic(() => import('./Drawer'), {
    ssr: false
});

const StyledHeader = styled(Header)`
    height: auto !important;
    background: inherit;
    padding: 0;
`;

interface Props {
    url: string;
    name: string;
    ads: Pick<PageAds, 'skyscraper' | 'billboard' | 'wideboardTop'>;
}

const HeaderSection = ({ url, name, ads }: Props): JSX.Element => {
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [shouldRenderDrawer, setShouldRenderDrawer] = useState<boolean>(false);

    useEffect(() => {
        setShouldRenderDrawer(window.outerWidth <= MOBILE_HEADER_WIDTH);
    }, []);
    const router = useRouter();
    useEffect(() => {
        setDrawerOpen(false);
    }, [router.asPath]);

    const toggleDrawer = () => {
        setDrawerOpen((prev: boolean) => !prev);
    };

    const { billboard, wideboardTop } = ads;

    return (
        <>
            <StyledHeader>
                <Row style={{ padding: '10px 0' }}>
                    <Col xs={0} md={24}>
                        <TopMenu />
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col
                        xs={{ order: 1, span: 18 }}
                        md={{ order: 1, span: 24 }}
                        xl={{ order: 1, span: 6 }}
                    >
                        <Link href={url}>
                            <Image src={Logotype} alt={name} />
                        </Link>
                    </Col>
                    <Col
                        xs={{ order: 3, span: 24 }}
                        md={{ order: 2, span: 12 }}
                        lg={{ order: 2, span: 24 }}
                        xl={{ order: 2, span: 18 }}
                    >
                        <Billboard id={billboard[0]} />
                    </Col>
                    <Col xs={{ order: 2, span: 6 }} md={{ span: 0, order: 7 }}>
                        <Row justify="end" align="middle">
                            <Col style={{ padding: 10 }}>
                                <HamburgerButton isOpen={isDrawerOpen} onClick={toggleDrawer} />
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        xs={{ order: 6, span: 0 }}
                        md={{ order: 4, span: 24 }}
                        lg={{ order: 4, span: 24 }}
                    >
                        <MainMenu />
                    </Col>
                    {wideboardTop[0] && (
                        <Col
                            xs={{ order: 4, span: 24 }}
                            md={{ order: 3, span: 12 }}
                            lg={{ order: 5, span: 24 }}
                        >
                            <Wideboard id={wideboardTop[0]} />
                        </Col>
                    )}
                </Row>
            </StyledHeader>
            {shouldRenderDrawer && <Drawer isOpen={isDrawerOpen} toggle={toggleDrawer} />}
        </>
    );
};

export default HeaderSection;
