import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { RightOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import Heading from './Heading';
import { merriweather } from 'styles/GlobalStyle';

const A = styled(Link)`
    color: ${(props) => props.color || props.theme.colors.primary}!important;
    :hover {
        text-decoration: none;
        color: ${(props) => props.color || props.theme.colors.brownishGrey}!important;
    }
`;

const SubSectionTitle = styled.p`
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 0;
    padding-top: 10px;
    color: ${(props) => props.theme.colors.greyishBrownTwo};
`;

const Icon = styled(RightOutlined).attrs({ size: 14 })``;

const Wrapper = styled.div`
    margin-bottom: 25px;
`;

const DividerStyled = styled(Divider)`
    border-top: 2px solid #b7cbd9;
    margin-top: 5px;
    @media (min-width: 768px) {
        width: 100%;
    }
    min-width: 80%;
`;

const MoreText = styled.small`
    font-family: ${merriweather.style.fontFamily};
    color: ${({ theme }) => theme.colors.brownishGrey};
    font-size: 12px;
    padding-left: 20px;
`;

interface Props {
    title: string | JSX.Element;
    level?: number;
    url?: string;
    id?: string;
    subTitle?: string;
    noArrow?: boolean;
    divider?: boolean;
    style?: Record<string, string | number>;
}

const SectionTitle = ({
    title,
    url = undefined,
    subTitle = undefined,
    id = undefined,
    level = 1,
    noArrow = false,
    divider = false,
    style = {}
}: Props): JSX.Element => {
    if (!url) {
        return (
            <Wrapper style={style}>
                <Heading level={level} id={id}>
                    {title}
                </Heading>
                {divider && <DividerStyled />}
                {subTitle && <SubSectionTitle>{subTitle}</SubSectionTitle>}
            </Wrapper>
        );
    }

    return (
        <Wrapper style={style}>
            <A href={url}>
                <Heading level={level} id={id}>
                    {title}
                </Heading>
                {noArrow || (
                    <>
                        <MoreText>więcej</MoreText> <Icon />
                    </>
                )}
            </A>
            {subTitle && <SubSectionTitle>{subTitle}</SubSectionTitle>}
        </Wrapper>
    );
};

export default SectionTitle;
