import React from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';

const Category = styled.h2`
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => props.color || props.theme.colors.greyishBrown};
`;

const DividerStyled = styled(Divider)`
    margin-top: 5px;
    width: 120%;
    min-width: 80%;
`;

interface Props
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
    category: string | JSX.Element;
}

const Subtitle = ({ category, ...restProps }: Props) => {
    return (
        <>
            <Category {...restProps}>{category}</Category>
            <DividerStyled />
        </>
    );
};

export default Subtitle;
