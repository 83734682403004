import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Menu, Popover, Row, Col, Modal, message } from 'antd';
import { ExclamationCircleOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { State } from 'reducer/reducer';
import { logout, openLoginModal } from 'reducer/actions';

const { SubMenu, Item } = Menu;
const { confirm } = Modal;

const hasChildren = ({ children }): boolean => {
    return typeof children !== 'undefined' && children.length > 0;
};

const MenuItem = styled(Item)`
    font-size: 14px;
    font-weight: 300;
    a {
        color: ${(props) => props.theme.colors.brownishGrey};
    }
    a {
        color: ${(props) => props.theme.colors.brownishGrey};
    }
`;

const MenuStyled = styled(Menu)`
    color: ${(props) => props.theme.colors.brownishGrey};
    justify-content: end;
    border-bottom: 0;
    li {
        border-bottom: 1px solid #f0f0f0;
    }
`;

const SubMenuStyled = styled(SubMenu)`
    .ant-menu-item > a {
        color: ${(props) => props.theme.colors.brownishGrey};
    }
    div > a {
        color: ${(props) => props.theme.colors.brownishGrey};
    }
`;

const ButtonStyled = styled(Button)`
    color: ${({ theme }) => theme.colors.brownishGrey};
    :hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: white;
        -webkit-transition: background-color 500ms linear;
        -ms-transition: background-color 500ms linear;
        transition: background-color 500ms linear;
    }
`;

function renderSubmenu(item) {
    if (hasChildren(item)) {
        return (
            <SubMenuStyled
                key={item.seoSlug}
                title={<Link href={`/${item.seoSlug}`}>{item.title}</Link>}
            >
                {item.children.map((child) => renderSubmenu(child))}
            </SubMenuStyled>
        );
    }
    return (
        <MenuItem key={item.seoSlug}>
            <Link href={`/${item.seoSlug}`}>{item.title}</Link>
        </MenuItem>
    );
}

interface NotLoggedInContentProps {
    openModal: () => void;
}

const NotLoggedInContent = ({ openModal }: NotLoggedInContentProps) => (
    <Row>
        <Col span={24}>
            <ButtonStyled block type="link" onClick={openModal}>
                Zaloguj
            </ButtonStyled>
        </Col>
        <Col span={24}>
            <Link href="/rejestracja">
                <ButtonStyled block type="link">
                    Zarejestruj
                </ButtonStyled>
            </Link>
        </Col>
    </Row>
);

const LoggedInContent = ({ logout }) => (
    <Row>
        <Col span={24}>
            <ButtonStyled block type="link" onClick={logout}>
                Wyloguj
            </ButtonStyled>
        </Col>
    </Row>
);

export const MenuSection = (): JSX.Element => {
    const [activeKeys, setActiveKeys] = useState(['home']);
    const menuCategories = useSelector((state: State) => state.menu);
    const router = useRouter();
    const user = useSelector((state: State) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        const path = router.asPath.split('/').filter((elem) => elem);
        if (path.length === 0) {
            setActiveKeys(['home']);
        }
        setActiveKeys(path);
    }, [router.asPath]);

    const onLogout = () => {
        confirm({
            title: 'Czy napewno chcesz się wylogować?',
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                localStorage.removeItem('token');
                localStorage.removeItem('tokenExpiresAt');
                dispatch(logout());
                message.info('Poprawnie wylogowano');
            }
        });
    };

    const topMenuCategories = menuCategories.filter((category) => category.type === 1);
    return (
        <MenuStyled selectedKeys={activeKeys} mode="horizontal">
            <MenuItem key="home">
                <Link href="/">Strona główna</Link>
            </MenuItem>
            <MenuItem key="e-wydania">
                <Link href="/archiwum">E-Wydania</Link>
            </MenuItem>
            {topMenuCategories.map((item) => {
                return renderSubmenu(item);
            })}
            <MenuItem key="szukaj">
                <Link href="/szukaj">
                    <SearchOutlined />
                </Link>
            </MenuItem>
            <MenuItem key="login">
                <Popover
                    placement="bottom"
                    content={
                        user ? (
                            <LoggedInContent logout={onLogout} />
                        ) : (
                            <NotLoggedInContent
                                openModal={() => {
                                    dispatch(openLoginModal());
                                }}
                            />
                        )
                    }
                    trigger="click"
                >
                    <UserOutlined />
                </Popover>
            </MenuItem>
        </MenuStyled>
    );
};

export default MenuSection;
