import React from 'react';
import { useSelector } from 'react-redux';
import { State } from 'reducer/reducer';
import MainMenu from 'components/MainMenu';

export const MainMenuContainer = (): JSX.Element => {
    const menuCategories = useSelector((state: State) => state.menu);

    const mainMenuCategories = menuCategories.filter((category) => category.type === 2);
    return <MainMenu items={mainMenuCategories} />;
};

export default MainMenuContainer;
